:root {
	/* --test-mode-bg1: #234da9;
	--test-mode-bg2: #183677;
	--test-mode-bg3: #152e66; */
	--test-mode-bg1: #1c3e82;
	--test-mode-bg2: #183c72;
	--test-mode-bg3: #0f2648;
}

@tailwind base;
@tailwind components;

/*LAYOUT*/
@import './footer.css';
@import './pagination.css';
/* @import './headings.css'; */
/* #region --- CONTAINER*/

html {
	scroll-snap-type: y mandatory;
}

section {
	margin: 5rem auto;
}

section.sales {
	max-width: 1080px;
	height: 100vh;
	scroll-snap-align: start;
}
/* #endregion */

/* Fixes footer and header to appropriate places */
div#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

@media (min-width: 1200px) {
	div#root {
		justify-content: space-between;
	}
}

/* body {
	font-size: 1rem;
	line-height: 1.8;
	color: #333;
	background-color: #f9f9f9;
} */

/* #region --- TYPOGRAPHY*/
body {
	@apply text-sky-800;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 1.6;
}

::placeholder {
	@apply text-gray-400;
}

/*  <uniquifier>: Use a unique and descriptive class name
 <weight>: Use a value from 400 to 700 */
/* .lora-<uniquifier> { */

.lora-regular {
	font-family: 'Lora', serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}

.poppins-thin {
	font-family: 'Poppins', sans-serif;
	font-weight: 100;
	font-style: normal;
}

.poppins-extralight {
	font-family: 'Poppins', sans-serif;
	font-weight: 200;
	font-style: normal;
}

.poppins-light {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-regular {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-semibold {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-bold {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-style: normal;
}

.poppins-extrabold {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-style: normal;
}

.poppins-black {
	font-family: 'Poppins', sans-serif;
	font-weight: 900;
	font-style: normal;
}

.poppins-thin-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 100;
	font-style: italic;
}

.poppins-extralight-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 200;
	font-style: italic;
}

.poppins-light-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-style: italic;
}

.poppins-regular-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-medium-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-style: italic;
}

.poppins-semibold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-style: italic;
}

.poppins-bold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-style: italic;
}

.poppins-extrabold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-style: italic;
}

.poppins-black-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 900;
	font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Poppins', Sans-Serif;
	/* margin-bottom: 1.5rem; */
	font-weight: 700;
	line-height: 1.15;
	letter-spacing: -0.022em;
}

h1 {
	margin-top: 2.986rem;
	font-size: 2.986rem;
}

h2 {
	margin-top: 2.488rem;
	font-size: 2.488rem;
}

h3 {
	margin-top: 2.074rem;
	font-size: 2.074rem;
}

h4 {
	margin-top: 1.728rem;
	font-size: 1.728rem;
}

h5 {
	margin-top: 1.44rem;
	font-size: 1.44rem;
}

h6 {
	margin-top: 1.2rem;
	font-size: 1.2rem;
}

/* p {
	font-size: 1rem;
} */

small {
	font-size: 0.833rem;
}
/* #endregion */

/* #region --- LINKS*/
a {
	@apply text-brand-500;
	@apply cursor-pointer;
}

a.sidebar-link {
	@apply hover:bg-brand-700;
	@apply hover:text-white;
}
/* #endregion */

@tailwind utilities;

/* SPINNER */
.circle {
	height: 3.85rem;
	width: 3.85rem;
	background-color: transparent;
	border-radius: 50%;
	display: inline-block;
}

/* MATERIAL ICONS */
.material-symbols-outlined {
	font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' 0, 'opsz' 48;
}

/* #region --- BULLET POINTS / LISTS*/
.normal-bullets {
	margin-bottom: 1.5rem;
}

.normal-bullets > li {
	margin-left: 1.8rem;
}

ol.normal-bullets {
	list-style: decimal;
}

ul.normal-bullets {
	list-style-type: disc;
}

ul.normal-bullets > li {
	list-style-type: circle !important;
}
/* #endregion */

/* #region --- CONTAINER*/
@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
}

@media (min-width: 992px) {
	.container {
		width: 970px;
	}
}

@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}

.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

p {
	margin-bottom: 1.25rem;
}

div.mb-0 > p {
	margin-bottom: 0 !important;
}
/* #endregion */

/* FORMS */
/* #region */
/* input,
textarea {
	@apply mt-1;
	@apply mb-3;
	@apply py-2;
	@apply px-3;
	@apply block;
	@apply w-full;
	@apply rounded-md;
	@apply border;
	@apply border-gray-300;
	@apply leading-tight;
	@apply shadow-sm;
	@apply text-gray-600;
	@apply focus:border-brand-200;
	@apply focus:ring;
	@apply focus:ring-brand-200;
	@apply focus:ring-opacity-50;
	@apply placeholder:italic;
	@apply placeholder:text-gray-400;
	@apply placeholder:font-light;
	@apply placeholder:text-sm;
} */

/* #endregion */

/* #region --- BUTTONS*/
button,
.btn {
	font-size: 0.88rem;
	@apply px-4 py-2;
	@apply rounded-md;
	@apply text-white;
	@apply font-semibold;
	@apply outline-none;
	@apply focus:outline-none;

	/*  animation */
	@apply transition-all;
	@apply transition;
	@apply duration-300;
	@apply ease-in-out;
	@apply cursor-pointer;
}

button:hover,
.btn:hover {
	/* filter: brightness(1.1); */
	opacity: 0.9;
	scale: 1.05;
}

button:disabled,
button[disabled] {
	border: 1px solid transparent;
	@apply border-gray-300;
	@apply bg-gray-300;
	@apply text-gray-500;
	opacity: 1;
	scale: none;
	cursor: not-allowed;
}

button:disabled:hover,
button[disabled]:hover {
	border: 1px solid transparent;
	@apply border-gray-300;
	@apply bg-gray-300;
	opacity: 1;
	scale: none;
}

button.no-scaling:hover,
.btn.no-scaling:hover {
	scale: none;
}
/* #endregion */

/* #region --- DASHBOARD*/
.dashboard-link {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	/* padding: 2rem 2rem; */
	padding-top: 1.5rem;
	padding-bottom: 1.8rem;
	text-align: center;
}
/* #endregion */

/* #region --- MODAL*/
/* #modal {
	@apply hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	justify-content: center;
	align-items: center;
	z-index: 9999;
} */
/* #endregion */

/* #region --- TEST MODE */
.bg-test-mode {
	min-height: 100%;
	/* Gradiend: */
	background: var(--test-mode-bg2);
	background: -moz-linear-gradient(
		122deg,
		var(--test-mode-bg3) 0%,
		var(--test-mode-bg2) 70%,
		var(--test-mode-bg1) 100%
	);
	background: -webkit-linear-gradient(
		122deg,
		var(--test-mode-bg3) 0%,
		var(--test-mode-bg2) 70%,
		var(--test-mode-bg1) 100%
	);
	background: linear-gradient(
		122deg,
		var(--test-mode-bg3) 0%,
		var(--test-mode-bg2) 70%,
		var(--test-mode-bg1) 100%
	);
	/* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f433e",endColorstr="#26a69a",GradientType=1); */
}

.answers-box {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: minmax(100px, auto);
}

.answer {
	margin: 0 0.8vh 1.5vh 0.8vh;
	width: 98%;
	border-radius: 5px;
	min-height: 100px;
	display: flex;
}

.answer > button {
	background: inherit;
	border: none;
	border-radius: 2px;
	box-shadow: none !important;
	color: var(--black-darker);
	cursor: pointer;
	padding: 2vmin 5vmin;
	width: 100%;
	transition: background-color 0.2s ease-out;
	text-align: left;
}

.answer > button:focus {
	background-color: #fff !important;
}

.selected-answer {
	@apply ring-1;
	@apply ring-brand-500;
}
.selected-answer > button {
	font-weight: 600;
}

.right-answer {
	@apply text-green-700;
	@apply bg-green-100;
	font-weight: 600;
}
.wrong-answer {
	@apply text-red-700;
	@apply bg-red-100;
}
.bg-right-answer {
	@apply bg-green-700;
	@apply text-white;
}

.bg-wrong-answer {
	@apply bg-red-500;
	@apply text-white;
}

.answer > button:disabled {
	cursor: auto;
	color: inherit;
	background: inherit;
}

.next-question-button {
	margin: 0 2.5vh;
	text-align: right;
}

@media only screen and (max-width: 790px) {
	.answers-box {
		grid-template-columns: repeat(1, 1fr) !important;
		grid-auto-rows: minmax(85px, auto) !important;
	}

	.answer {
		min-height: 85px;
		min-width: 98%;
	}
}
/* #endregion */

/* ===== STATS =====*/
.circle {
	height: 3.85rem;
	width: 3.85rem;
	background-color: transparent;
	border-radius: 50%;
	display: inline-block;
}
.circle2 {
	height: 5rem;
	width: 5rem;
	background-color: transparent;
	border-radius: 50%;
	display: inline-block;
}
/*#region */
.stats {
	position: relative;
	transition: box-shadow 0.25s;
}

.stat-cards {
	display: flex !important;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin-top: 4rem;
}

.stat-cards .card-panel {
	background: var(--white);
}

.stat-cards .card-panel .circle2 {
	border: 2px solid transparent;
}

.stat-cards .data {
	@apply bg-alt1-200;
	font-size: 1.45rem;
	padding-top: 0.75rem;
}

.inset-shadow {
	-webkit-box-shadow: inset 2px 0 2px 0 rgba(0, 0, 0, 0.14),
		inset 3px 0 1px -2px rgba(0, 0, 0, 0.12),
		inset 1px 0 5px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: inset 2px 0 2px 0 rgba(0, 0, 0, 0.14),
		inset 3px 0 1px -2px rgba(0, 0, 0, 0.12),
		inset 1px 0 5px 0 rgba(0, 0, 0, 0.2);
	box-shadow: inset 2px 0 2px 0 rgba(0, 0, 0, 0.14),
		inset 3px 0 1px -2px rgba(0, 0, 0, 0.12),
		inset 1px 0 5px 0 rgba(0, 0, 0, 0.2);
}
.dashboard .row .stats {
	min-height: 100vmax;
	padding: 0 4%;
}
/*#endregion*/

/* #region --- FOOTER*/
progress {
	border-radius: 7px;
	width: 80%;
	height: 1rem;
	/* margin-left: -11.5%; */
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
progress::-webkit-progress-bar {
	background-color: #eee;
	border-radius: 7px;
}
progress::-webkit-progress-value {
	/* background-color: blue; */
	@apply bg-brand-400;
	border-radius: 7px;
	/* box-shadow: 1px 1px 5px 3px rgba(255, 0, 0, 0.8); */
}
/* progress::-moz-progress-bar { */
/* style rules */
/* } */
/* #endregion */
