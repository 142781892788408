.progress-div {
	/* background-color: rgb(233, 233, 233); */
	border-radius: 0.5rem;
}

.progress-bar {
	@apply bg-brand-500;
	height: 15px;
	border-radius: 1rem;
	transition: 1s ease;
	transition-delay: 0.5s;
}

/* -------------------------------------------- */

.steps-progress-bar-container {
	@apply text-gray-400;
	max-width: 960px;
	margin: 0 auto;
	padding: 2rem 0;
	/* font-family: 'Exo', sans-serif; */
	/* color: var(--gray); */
	counter-reset: step-count;
}
.steps-progress-bar {
	height: 10px;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}
.progress-step {
	text-align: center;
	position: relative;
}
.progress-step:before,
.progress-step:after {
	@apply bg-brand-500;
	content: '';
	height: 2px;
	position: absolute;
	z-index: -1;
	top: 15px;
}
.progress-step:after {
	left: 50%;
	width: 100%;
}
.progress-step:last-of-type.is-active:after {
	@apply bg-brand-500;
}
@media screen and (min-width: 640px) {
	.progress-step:first-of-type:before {
		right: 50%;
		width: 50%;
	}
}
@media screen and (max-width: 640px) {
	.progress-step:first-of-type:before,
	.progress-step:last-of-type:after {
		background-color: white !important;
	}
}
.progress-step:last-of-type:after {
	left: 50%;
	width: 50%;
}
.progress-step .step-count {
	@apply bg-brand-500;
	@apply text-white;
	height: 30px;
	width: 30px;
	margin: 0 auto;
	border-radius: 50%;
	line-height: 30px;
	z-index: 100;
	border: 7px solid white;
	font-size: 0.65rem;
	font-weight: 500;
	/* --------- */
	display: flex;
	align-items: center;
	justify-content: center;
}
.progress-step .step-count:before {
	counter-increment: step-count;
	content: counter(step-count);
}
.progress-step.is-active .step-description {
	font-weight: 500;
	font-size: 1rem;
	/* margin-top: 0.7rem; */
}
.progress-step.is-active:after {
	@apply bg-gray-400;
}
.progress-step.is-active ~ .progress-step .step-count {
	@apply bg-gray-400;
}
.progress-step.is-active ~ .progress-step:after {
	@apply bg-gray-400;
}
.step-description {
	font-size: 0.8rem;
}

/* @media screen and (max-width: 767px) {
	.progress-step:last-of-type:after {
		left: 0;
		width: 50%;
	}
} */

.progress-step.is-active .step-count {
	transform: scale(1.5);
}
